// 统一请求路径前缀在libs/axios.js中修改
import { getRequest, postRequest, putRequest, postBodyRequest, getNoAuthRequest, postNoAuthRequest } from '@/libs/axios';

// 分页获取数据
export const getAppReceiptList = (params) => {
    return postRequest('/busi/appReceipt/getByCondition', params)
}
// 添加
export const addAppReceipt = (params) => {
    return postRequest('/busi/appReceipt/insert', params)
}
// 编辑
export const editAppReceipt = (params) => {
    return postRequest('/busi/appReceipt/update', params)
}
// 删除
export const deleteAppReceipt = (params) => {
    return postRequest('/busi/appReceipt/delByIds', params)
}